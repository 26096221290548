import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import StarRatings from "react-star-ratings";

const Map = (props) => {
  const [placeDetails, setPlaceDetails] = useState({
    rating: 3,
    user_ratings_total: 75,
  });

  const handleApiLoaded = (map, maps) => {
    let position = new maps.LatLng(28.758, -81.352);
    new maps.Marker({
      position: position,
      map: map,
      title: "Sunstate Medical Associates",
    });

    let request = {
      placeId: "ChIJ7R34BbBy54gRgbXsOMF2da0",
      fields: ["rating", "user_ratings_total"],
    };
    let service = new maps.places.PlacesService(map);
    service.getDetails(request, (details, status) => {
      if (status === maps.places.PlacesServiceStatus.OK) {
        let fetchedDetails = {
          rating: details.rating,
          user_ratings_total: details.user_ratings_total,
        };
        setPlaceDetails(fetchedDetails);
      }
    });

    // request = {
    //   location: position,
    //   radius: "100",
    // };
    // service.nearbySearch(request, (results, status) => {
    //   if (status == maps.places.PlacesServiceStatus.OK) {
    //     results.forEach((place) => {
    //       new maps.Marker({
    //         position: place.geometry.location,
    //       });
    //       console.log(place);
    //     });
    //   }
    // });
  };

  return (
    <div className="relative w-full h-74" id="mapContainer">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAk6nLm5mpFwr1A9p3waALWfqq6GLDvnRE",
          libraries: ["places"],
        }}
        defaultCenter={{ lat: 28.758, lng: -81.352 }}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
      <div className="flex flex-row justify-start items-center absolute w-68 top-0 left-0 mt-4 ml-4 z-30 bg-white rounded shadow-lg">
        <div className="px-2 py-2 flex flex-row w-full">
          <div className="flex flex-col w-3/4">
            <p className="text-black font-semibold text-md">
              Sunstate Medical Associates
            </p>
            <p className="text-gray-800 text-xs">
              758 N Sun Dr #104, Lake Mary, FL 32746
            </p>
            <div className="flex flex-row items-center mb-2">
              <p className="text-sm font-semibold text-google-orange mr-1">
                {placeDetails.rating}
              </p>
              <StarRatings
                rating={placeDetails.rating}
                starDimension=".75rem"
                starSpacing="0"
                starRatedColor="#e7711b"
              />
              <a href="https://goo.gl/maps/r7qKbJNGUDcJmxiv7">
                <p className="text-xs text-google-blue hover:underline ml-2">{`${placeDetails.user_ratings_total} reviews`}</p>
              </a>
            </div>
            <a href="https://goo.gl/maps/r7qKbJNGUDcJmxiv7">
              <p className="text-google-blue text-xs hover:underline">
                View larger map
              </p>
            </a>
          </div>
          <div className="flex flex-col items-center justify-start w-1/4">
            <span className="text-xl text-google-blue">
              <i className="fas fa-directions"></i>
            </span>
            <a href="https://goo.gl/maps/r7qKbJNGUDcJmxiv7">
              <p className="text-xs text-google-blue hover:underline">
                Directions
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
